export const isLoggedIn = (state) => !!state.token;
export const isNonProfit = (state) => {
  const nonProfitOrgs = ['The Heritage Foundation'];
  return nonProfitOrgs.includes(state.account?.orgName) || nonProfitOrgs.includes(state.account?.name);
};
export const updateCardDescription = (state, getters) => {
  if (getters.isNonProfit) {
    return 'Please update your card to continue supporting ';
  }
  return 'Please update your card to keep using ';
};
